
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































































































































$visual-height-mobile: 20rem;

.highlight__counter {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  display: flex;
  font-family: $ff-alt;
  font-weight: 700;
  line-height: 1;

  @include mq($until: m) {
    top: 0;
    right: 0;
    transform: translateY(-150%);
  }
}

.highlight__counter__current {
  align-self: flex-start;
  color: $c-green-light;
}

.highlight__counter__separator {
  opacity: 0.5;

  @include mq(m) {
    margin: 0.5em 6px;
    color: $c-white;
  }
}

.highlight__counter__total {
  align-self: flex-end;
  opacity: 0.5;

  @include mq(m) {
    color: $c-white;
  }
}

.highlight__slideshow {
  display: flex;
  overflow: hidden;
  clip-path: inset(0 1px 0 1px);
}

.highlight__slideshow__slide {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;
  transform: translateX(calc(var(--offset) * -100%));
}

.highlight-wrapper {
  position: relative;
}

.highlight {
  @include default-margin;
  @include bottom-dashed-line;

  width: col(14);
  margin-right: col(1);
  margin-left: col(1);
  padding-bottom: $spacing * 5;
}

.highlight__content {
  position: relative;
}

.highlight-inner {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: $spacing * 2;

  @include mq($until: m) {
    flex-direction: column;
  }
}

.highlight__title {
  width: col(14);
  // margin: 0 col(1);
  color: $c-gray-medium;
  font-weight: 400;
}

.highlight__picture-outer {
  @include center-y;

  z-index: 1;

  @include mq($until: m) {
    position: relative;
    width: 100%;
    height: $visual-height-mobile;
    transform: translateY(0);
  }

  @include mq(m) {
    left: 0;
    width: col(8);
    height: 80%;
  }
}

.highlight__picture-inner {
  display: none;
  width: 100%;
  height: 100%;
  clip-path: inset(0 100% 0 0);
}

.highlight__picture {
  @include image-fit;
}

.hightlight__picture__progress {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: col(9);
  height: 5px;
  transform-origin: 0 0;
  transform: scaleX(0);
  background-color: $c-green-light;

  @include mq($until: m) {
    left: 0;
    width: 100%;
  }
}

.highlight__main {
  flex-shrink: 0;
  width: col(9);
  padding: col(1.5) col(2);
  color: $c-white;
  background-color: $c-green-dark;

  &.is-slideshow {
    padding: col(1.5) col(2) col(1);
  }

  @include mq($until: m) {
    position: relative;
    z-index: 1;
    width: 100%;
    padding: col(2) col(2);
  }
}

.highlight__main__headline {
  @extend %text-uppercase;
  @extend %fw-bold;
}

.highlight__main__type {
  @include font-color;
}

.highlight__main__date {
  color: rgba($c-white, 0.6);
}

.highlight__main__title {
  @extend %fw-bold;

  margin-top: $spacing / 2;
}

.highlight__main__text {
  margin-top: $spacing;
  color: rgba($c-white, 0.7);
}

.highlight__main__collapse {
  .collapse__header,
  .collapse__text-inner a {
    color: $c-white;
  }

  .collapse__header__icon {
    fill: $c-white;
  }
}

.highlight__controls {
  position: absolute;
  z-index: 1;
  right: 30px;
  bottom: 30px;

  ::v-deep {
    .next {
      margin-left: 11px;
      background-color: $c-green-light;
      border-color: $c-green-light;
      transform: rotate(180deg);
    }
  }

  @include mq($until: m) {
    top: 0;
    right: 0;
    bottom: unset;
    justify-content: space-between;
    width: 100%;
    padding: 0 col(1.5);
    transform: translateY(-50%);
  }
}

.highlight__controls__prevnext {
  display: flex;

  @include mq($until: m) {
    display: flex;
    justify-content: space-between;
  }
}

.cta__link-container {
  @include mq(m) {
    margin-top: auto;
  }
}
