
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





























.nav-menu {
  display: flex;
  align-items: center;
  width: 100%;
  height: 10rem;
  padding-left: 1.5rem;
  line-height: 10rem;
  border-bottom: 1px solid $c-gray-lighter;
  border-left: 1px solid $c-gray-lighter;

  &.is-hidden {
    display: none;
  }

  @include mq($until: xxl) {
    display: none;
  }
}

.nav-menu__categories {
  display: flex;
  justify-content: space-between;

  @include mq($until: xl) {
    flex-direction: column;
    align-items: center;
    margin-top: $spacing;
  }
}

.nav-menu__item {
  margin: 0 2rem;
  cursor: pointer;
  padding: 0.3rem 0;

  // &::before {
  //   content: '';
  //   position: absolute;
  //   bottom: -0.1rem;
  //   left: 0;
  //   display: block;
  //   width: 100%;
  //   height: 0.3rem;
  //   background-color: $c-green-light;
  //   opacity: 0;
  //   transition: opacity 0.3s ease-out;
  // }

  .nav-menu__subitem {
    position: absolute;
    bottom: 0;
    left: col(-10);
    display: none;
    width: col(16, 1);
    line-height: 2rem;
    background: $c-white;
    border-top: 1px solid $c-gray-lighter;
    opacity: 0;
    transition: opacity 0.3s ease-out;
    transform: translateY(100%);
  }

  &:hover {
    .nav-sub {
      display: flex;
      opacity: 1;
    }

    .nav-menu__item__link {
      border-bottom: 3px solid $c-green-light;
    }
  }

  @include mq($until: xl) {
    @include bottom-dashed-line;

    width: 100%;
    padding: $spacing * 1.5 0;
    text-align: center;
  }
}

.nav-menu__item__link {
  @extend %fw-bold;

  color: $c-green-dark;
  font-family: $ff-alt;
  text-decoration: none;
  border-bottom: 3px solid transparent;

  &.router-link-exact-active {
    pointer-events: none;
    color: $c-green-light;
  }
}

.nav-menu__subitem__item {
  padding: 5rem;

  &:nth-child(2) {
    columns: 2;
    border-left: 1px solid $c-gray-lighter;
  }
}

.nav-menu__subitem__title {
  color: $c-green-light;
}

.nav-menu__subitem__links {
  margin-top: 2rem;
}

.nav-menu__subitem__link {
  padding: 1rem 0;

  a {
    text-decoration: none;
  }
}

.nav-menu__universe-switcher {
  margin-left: auto;

  &.is-hidden {
    display: none;
  }
}
