
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































.nav-sub {
  position: absolute;
  z-index: 99;
  bottom: 0;
  left: col(2.85, 16);
  display: none;
  width: 68vw;
  line-height: 2rem;
  background: $c-white;
  border: 1px solid $c-gray-lighter;
  opacity: 0;
  transition: opacity 0.3s ease-out;
  transform: translateY(100%);

  @include mq(xxxl) {
    left: col(1.85, 16);
  }

  // &::after {
  //   content: '';
  //   position: fixed;
  //   z-index: -1;
  //   top: 0;
  //   left: 0;
  //   display: block;
  //   width: 100vw;
  //   height: 100vh;
  //   background-color: rgba($c-green-light, 0.5);
  // }

  .is-active & {
    display: block;
    opacity: 1;
  }

  .mega-menu & {
    position: relative;
    left: auto;
    width: auto;
    border: 0;
    transform: translateY(0);
  }
}

.nav-sub-inner {
  padding: 5rem;

  &:first-child {
    .nav-sub__title {
      margin-top: 0;
    }
  }

  .nav-sub__list {
    margin-top: 2rem;
  }

  .mega-menu & {
    // margin-bottom: 4rem;
    padding: 0;
  }

  &:nth-child(2) {
    border-left: 1px solid $c-gray-lighter;

    .nav-sub__list {
      columns: 2;
    }

    .mega-menu & {
      border-left: 0;

      .nav-sub__list {
        columns: 1;
      }
    }
  }
}

.nav-sub__title {
  margin-top: 4rem;
  color: $c-green-light;
  font-family: $ff-alt;

  .mega-menu & {
    display: block;
    margin-bottom: 2rem;
    color: $c-green-light;
    font-size: 1.2rem;
    font-weight: 100;

    @include mq(l) {
      font-size: 1.8rem;
    }
  }
}

.nav-sub__item {
  padding: 1rem 0;

  a {
    text-decoration: none;
  }

  .mega-menu & {
    @extend %fw-bold;

    margin-bottom: 1.8rem;
    padding: 0;
    font-family: $ff-alt;
    font-size: 1.6rem;

    @include mq(l) {
      color: $c-green-dark;
    }
  }
}

.nav-sub__item__link {
  transition: color 0.3s ease-out;

  &:hover {
    color: $c-green-light;
  }
}
