
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









































































































































































.popup__container {
  position: relative;
  width: 100%;
  max-width: 90rem;
  height: 100%;
  margin: 0 auto;
  background-color: $c-white;
  border-left: 1rem solid $c-green-light;
  border-radius: 0.5rem;
}
