
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































































.picture-outer {
  @include default-margin;
}

.picture-inner {
  position: relative;
  overflow: hidden;
  width: 100%;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 30%;
  }
}

.picture {
  @include image-fit;

  clip: rect(0, 0, 100vh, 0);
  background-color: rgba(random(255), random(255), random(255), 0.2);
}

.picture__caption-outer {
  @extend %caption-outer;
}

.picture__caption {
  @extend %caption;
}
