
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































.nav {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 50%;
  width: 100%;
  border-bottom: 1px solid $c-gray-lighter;
  transform: translate(-50%, 00);

  @include mq(l) {
    height: 10rem;
  }

  @include mq($until: xxxl) {
    padding-left: col(1);
  }
}

.nav-inner {
  position: relative;
  display: flex;
  align-items: center;
  height: 7rem;

  @include mq(l) {
    height: 10rem;
  }
}

.nav__logo-outer {
  position: relative;
  flex-shrink: 0;
  width: 12.5rem;
  height: 5.5rem;
  margin-right: col(1/2);

  @include mq(m) {
    height: 7rem;
  }
}

.nav__logo {
  @include image-fit(contain);
}

.footer-inner {
  display: flex;
  justify-content: space-between;

  @include mq($until: m) {
    flex-direction: column;
    align-items: center;
  }
}

.nav__aside {
  @include mq($until: m) {
    @include bottom-dashed-line;

    width: 100%;
    padding: $spacing * 2;
    text-align: center;
  }
}

.nav__universe-switcher {
  margin-left: auto;

  &.is-hidden {
    display: none;
  }
}

.nav__search {
  position: relative;
  flex-shrink: 0;
  width: 7rem;
  height: 7rem;
  background-color: $c-green-dark;

  label {
    display: none;
  }

  @include mq(l) {
    width: 10rem;
    height: 10rem;
  }
}

.nav__search__icon {
  @include center-xy;

  fill: $c-white;
  // stroke: $c-white;
  width: 1.5rem;
  height: 1.5rem;

  @include mq(l) {
    width: 2.5rem;
    height: 2.5rem;
  }
}

// HEADROOM RELATED STICKY NAV

.headroom,
[class*='headroom--'] {
  position: fixed;
  width: 100%;
  background-color: $c-white;
  transition: transform 0.7s $ease-out-quad;

  &[class*='--top'] {
    position: absolute;
  }

  &[class*='--unpinned']:not(.is-mega-open) {
    transform: translate(-50%, -100%);
    pointer-events: none;
  }

  &::after {
    @include center-xy;

    content: '';
    z-index: -1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $c-white;
  }
}
