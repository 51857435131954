
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































































































































































$breakpoint: l;

.mega-menu {
  margin-left: auto;
}

.mega-menu__trigger {
  position: relative;
  display: block;
  width: 7rem;
  height: 7rem;
  margin-left: auto;
  padding: 2rem 3rem;
  text-decoration: none;
  background-color: $c-green-light;

  @include mq($breakpoint) {
    width: 10rem;
    height: 10rem;
  }
}

.mega-menu__trigger__hamburger {
  @include center-xy;

  span {
    display: block;
    width: 2rem;
    height: 2px;
    margin-bottom: 4px;
    background-color: $c-white;
    transition: transform 0.3s, opacity 0.4s;

    &:nth-child(3) {
      margin-bottom: 0;
    }

    @include mq(l) {
      width: 3rem;
      margin-bottom: 7px;
    }
  }

  .is-open & {
    span:nth-child(1) {
      transform: translateY(6px) rotate(45deg) !important; /* stylelint-disable-line declaration-no-important */

      @include mq(l) {
        transform: translateY(10px) rotate(45deg) !important; /* stylelint-disable-line declaration-no-important */
      }
    }

    span:nth-child(2) {
      opacity: 0;
      transform: translate(
        20px
      ) !important; /* stylelint-disable-line declaration-no-important */
    }

    span:nth-child(3) {
      transform: translateY(-6px) rotate(-45deg);

      @include mq(l) {
        transform: translateY(-8px) rotate(-45deg);
      }
    }
  }

  .mega-menu__trigger:hover & {
    span:nth-child(1) {
      transform: translate(5px);
    }

    span:nth-child(2) {
      transform: translate(10px);
    }
  }
}

.mega-menu__container {
  position: fixed;
  z-index: -1;
  top: 7rem;
  left: 0;
  display: none;
  width: 100vw;
  padding-bottom: 4.4rem;
  background-color: $c-white;
  border-top: 1px solid $c-gray-lighter;
  transition: transform 0.15s;

  a {
    text-decoration: none;
  }

  .is-open & {
    display: block;
  }

  &.is-sub-open {
    transform: translateX(-100%);
  }

  @include mq(#{$breakpoint}) {
    top: 10rem;
    height: calc(100vh - 10rem);
    padding-bottom: 0;

    &.is-sub-open {
      transform: none;
    }
  }
}

.mega-menu__container__inner {
  overflow-y: scroll;
  height: calc(100vh - 7rem);

  @include mq($breakpoint) {
    display: flex;

    > * {
      height: calc(100vh - 10rem);
    }
  }
}

.mega-menu__main {
  padding: 12vh 0 $spacing * 3 col(1);
  background-color: $c-green-dark;

  @include mq(#{$breakpoint}) {
    position: relative;
    width: col(6);
    padding: 20vh 0 0 col(1);
    border-right: 1px $c-gray-light solid;
  }
}

.mega-menu__main__item {
  display: block;
  margin-bottom: 3.2rem;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.2;
  transition: color 0.3s $ease;

  > a {
    display: block;
    color: $c-white;
  }

  &:hover {
    text-decoration: none;
  }

  @include mq(#{$breakpoint}) {
    font-size: 3.6rem;

    &:hover,
    &.is-active {
      > a {
        color: $c-green-light;
      }
    }
  }
}

.mega-menu__main__item__title {
  @extend %fw-bold;

  position: relative;
  display: flex;
  align-items: center;
  font-family: $ff-alt;

  @include mq(#{$breakpoint}) {
    .is-active &,
    .mega-menu__main__item:hover & {
      .mega-menu__main__item__arrow {
        display: block;
      }
    }
  }
}

.mega-menu__main__item__subtitle {
  display: block;
  color: $c-gray-dark;
  font-size: 1.6rem;
  font-weight: 500;

  @include mq(#{$breakpoint}) {
    font-size: 1.8rem;
  }
}

.mega-menu__main__item__arrow {
  margin-top: 0.5rem;
  margin-left: 2rem;
  transform: rotate(-90deg);
  fill: $c-green-light;

  @include mq(#{$breakpoint}) {
    display: none;
    margin-top: 1rem;
  }
}

.nav-sub {
  @include mq(#{$breakpoint}) {
    flex: 1;
  }
}

.mega-menu__sub-item {
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  opacity: 0;

  .is-active & {
    z-index: 10;
    opacity: 1;
  }

  @include mq(#{$breakpoint}) {
    width: 100%;
    height: 100%;
    padding-left: col(1);
    transition: opacity 0.2s $ease;
  }
}

.mega-menu__sub-item__close {
  @extend %fw-bold;

  display: block;
  padding: 2rem col(1);
  font-family: $ff-alt;
  background-color: $c-white;

  @include mq(#{$breakpoint}) {
    display: none;
  }
}

.mega-menu__sub-item__close__arrow {
  transform: rotate(90deg);
}

.mega-menu__sub-item__container {
  @include scrollbars;

  overflow-y: scroll;
  height: calc(100% - 7rem);
  padding: 4rem col(1) 0;
  background-color: $c-white;

  @include mq(#{$breakpoint}) {
    height: 100%;
    padding: 0;
    padding-top: 20vh;
  }
}

.mega-menu__aside {
  @include mq(#{$breakpoint}) {
    position: absolute;
    right: 0;
    width: col(4);
  }
}

.mega-menu__aside__cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 6rem 3rem 15rem;
  color: $c-green-dark;
  font-family: $ff-alt;
  background-color: $c-yellow-medium;
  cursor: pointer;

  @include mq(#{$breakpoint}) {
    position: absolute;
    bottom: 0;
    height: 32vh;
    min-height: 15rem;
    padding: 0 0 0 5rem;
    font-weight: 500;
    line-height: 1.3;

    &:hover {
      background-color: rgba($c-yellow-medium, 0.9);
    }
  }
}

.mega-menu__aside__cta__title {
  font-size: 2.6rem;
  line-height: 1.2;

  strong {
    color: $c-white;
  }
}

.mega-menu__aside__cta__link {
  margin-top: $spacing;
}

.mega-menu__aside__links {
  padding: 5rem col(1);
  border-left: 1px solid $c-gray-lighter;

  @include mq(#{$breakpoint}) {
    padding: 9.5vh 1rem 7.5vh 5rem !important; /* stylelint-disable-line declaration-no-important */
  }
}

.mega-menu__aside__links__items {
  margin: 0 0 2rem;
  padding: 0;
}

.mega-menu__aside__links__item {
  margin-bottom: 2.5vh;
  font-family: $ff-alt;
  line-height: 1;

  @include mq(#{$breakpoint}) {
    list-style-type: none;
    font-size: 1.6rem;
    font-weight: 500;

    a {
      color: $c-green-dark;
      transition: color 0.3s ease-out;

      &:hover {
        color: $c-green-light;
      }
    }
  }
}

.mega-menu__aside__social {
  margin-top: 2rem;

  .social-network__link__icon {
    fill: $c-green-dark;
  }

  .social-network {
    margin: 0;
  }

  @include mq($breakpoint) {
    margin-top: 3.2rem;
  }
}
