
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












.error {
  margin: 2rem;
  padding: 0 2rem 2rem;
  border: 1px dotted $c-dev-error;
}

.error__box {
  margin: 1rem 0 0;
  padding: 2rem;
  color: $c-white;
  font-size: 1.2em;
  background-color: $c-dev-error;
}
