
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












.flexible-collapse {
  @include default-margin;

  .duo + & {
    margin-top: $spacing;
  }
}

.flexible-collapse-inner {
  width: col(14);
  margin-right: col(1);
  margin-left: col(1);

  @include mq(m) {
    display: flex;
    justify-content: space-between;
  }
}

.flexible-collapse__title {
  @extend %text-center;

  margin-top: $spacing * 1.5;
  color: $c-green-dark;
}

.flexible-collapse__collapse {
  position: relative;

  @include mq(m) {
    width: col(7);
  }
}
