
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































.page-nav {
  pointer-events: none;
  font-size: 1.6rem;
  line-height: 1em;
}

.page-nav--sticky {
  position: sticky;
  z-index: 1;
  top: 0;
  background-color: $c-white;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: $c-gray-lighter;
    transition: opacity 0.3s ease-out;
    opacity: 0;
  }
}

.page-nav.page-nav--stuck {
  transition: transform 0.75s ease-out;

  &::after {
    opacity: 1;
  }

  @include mq(xl) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  ::v-deep {
    .page-nav__item__link {
      @include mq(xl) {
        padding-top: 40px;
        padding-bottom: 40px;
      }

      &::before {
        top: unset;
        bottom: 0;
      }
    }
  }
}

.page-nav--hide {
  transform: translateY(-100%);
}

.page-nav-inner {
  position: relative;
  width: col(14);
  margin: 0 col(1);
}

.page-nav__label,
.page-nav__list {
  pointer-events: all;

  @include mq($until: xl) {
    max-width: 240px;
  }
}

.page-nav__label {
  @extend %fw-bold;

  position: relative;
  overflow: hidden;
  padding: $spacing $spacing * 3 $spacing $spacing;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid $c-gray-light;
  cursor: pointer;

  .page-nav.is-open & {
    color: $c-green-light;
  }

  @include mq(xl) {
    display: none;
  }
}

.page-nav__label__arrow {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: $spacing;
  display: block;
  width: 24px;
  height: 24px;
  fill: $c-green-dark;
  user-select: none;
  pointer-events: none;
  transform: translateY(-50%) rotate(0);
  transition: fill 0.2s ease-out, transform 0.2s ease-out;
  will-change: transform;

  .page-nav.is-open & {
    transform: translateY(-50%) rotate(180deg);
  }

  .page-nav.is-selected & {
    transform: translateY(-50%) translateX(-30px);
  }

  .page-nav.is-open.is-selected & {
    transform: translateY(-50%) translateX(-30px) rotate(180deg);
  }
}

.page-nav__list {
  @include mq($until: xl) {
    position: absolute;
    z-index: 99;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: $c-white;
    border: 1px solid $c-gray-light;
    border-top: 0;
  }

  @include mq(xl) {
    display: flex !important; // stylelint-disable-line declaration-no-important
  }
}

.page-nav__item {
  @include mq($until: xl) {
    padding-bottom: $spacing / 4;
    transition: background-color 0.2s ease-out;

    &.router-link-exact-active,
    &:hover {
      background-color: $c-green-dark;
    }
  }

  @include mq(xl) {
    & + & {
      margin-left: 5rem;
    }
  }
}

.page-nav__item__link {
  @extend %fw-bold;
  @extend %text-nodecoration;

  position: relative;
  display: block;
  width: 100%;
  font-family: $ff-alt;

  @include mq($until: xl) {
    padding: $spacing;

    .page-nav__item.router-link-exact-active & {
      cursor: default;
    }

    .page-nav__item.router-link-exact-active &,
    .page-nav__item:hover & {
      color: $c-white;
    }
  }

  @include mq(xl) {
    color: $c-gray-medium;

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: calc(100% + 5px);
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: $c-green-light;
      transform-origin: left;
      transform: scaleX(0);
      transition: transform 0.3s $ease-out-quad;
    }

    &:hover,
    .page-nav__item.router-link-exact-active & {
      color: $c-green-dark;
    }

    &:hover::before,
    .page-nav__item.router-link-exact-active &::before {
      transform: scaleX(1);
    }
  }
}
