
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















$gutter: 10px;

.grid-container {
  width: col(14);
  margin-left: col(1);

  @include mq(m) {
    width: col(7);
    margin-left: col(8);
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter;
  margin-right: -$gutter;
  margin-bottom: -$gutter * 2;
}

.grid__item {
  margin-bottom: $gutter * 2;
  box-sizing: border-box;
  padding-left: $gutter;
  padding-right: $gutter;
  flex-grow: 1;
  width: 50%;

  &.is-odd {
    width: 33.333%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
