
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































































.contact-people {
  @include mq(m) {
    display: flex;
  }
}

.contact-people__title-outer {
  flex-shrink: 0;
  width: col(14);
  margin: 0 col(1);

  @include mq(m) {
    width: col(4);
    margin: 0 0 0 col(1);
  }
}

.contact-people__title {
  @extend %fw-bold;

  color: $c-white;
  font-size: 1.6rem;
  line-height: 1.2em;
}

.contact-people__list {
  @extend %list-nostyle;

  width: col(14);
  margin: 0 col(1);
  color: $c-white;
  font-size: 1.4rem;
  line-height: 1.2;

  .contact-people__title-outer + & {
    @include mq($until: m) {
      margin-top: $spacing;
    }
  }

  @include mq(s) {
    display: flex;
    flex-wrap: wrap;
  }

  @include mq(m) {
    width: col(9);
    margin: 0 0 0 col(1);
  }
}

.contact-people__item {
  color: $c-white;

  @include mq($until: s) {
    & + & {
      margin-top: $spacing * 1.5;
    }
  }

  @include mq(s) {
    width: calc(50% - #{$spacing / 2});

    &:nth-child(even) {
      margin-left: $spacing;
    }

    &:nth-child(n + 3) {
      margin-top: $spacing * 2;
    }
  }
}

.contact-people__item-inner {
  display: flex;

  .contact-people__item__role + & {
    margin-top: $spacing;
  }
}

.contact-people__item__role {
  @extend %fw-bold;

  font-size: 1.6rem;
  line-height: 1.2em;
  opacity: 0.4;
}

.contact-people__item__picture-outer {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: 64px;
  height: 64px;
  border-radius: 6.4rem;
}

.contact-people__item__placeholder {
  width: 64px;
  height: 64px;
  fill: $c-white;
}

.contact-people__item__picture {
  @include image-fit;
}

.contact-people__item__infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: $spacing;
  font-size: 1.4rem;
  line-height: 1.2em;
}

.contact-people__item__infos__mail {
  @extend %text-nodecoration;

  color: $c-white;
  transition: color 0.2s ease-out;

  &:hover {
    color: $c-green-light;
  }
}

.contact-people__item__infos__name,
.contact-people__item__infos__job,
.contact-people__item__infos__department,
.contact-people__item__infos__mail {
  margin-top: $spacing / 2;
  font-size: 1.4rem;
  line-height: 1.2em;

  &:first-child {
    margin-top: 0;
  }
}
