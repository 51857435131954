
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































.popup-biography {
  @include scrollbars(2px, $c-green-light, $c-gray-lighter);

  overflow-y: scroll;
  height: calc(100% - 8rem);
  margin-top: 4rem;
  margin-right: 4rem;
  margin-bottom: 4rem;

  @include mq(m) {
    height: calc(100% - 16rem);
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
}

.popup-biography-inner {
  display: flex;
  padding: 0 col(2);
}

.popup-biography__picture-outer {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: 15rem;
  min-width: initial;
  height: 15rem;
  min-height: initial;
  margin-top: 1rem;
  border-radius: 15rem;
}

.popup-biography__picture {
  @include image-fit;
}

.popup-biography__content {
  margin-top: $spacing;
  margin-left: col(1);
}

.popup-biography__content__name {
  @extend %fw-bold;

  color: $c-green-dark;
  font-size: 2rem;
}

.popup-biography__content__role {
  @extend %fw-bold;

  color: $c-gray-medium;
}

.popup-biography__content__social {
  @include bottom-dashed-line;

  margin-top: $spacing * 2;

  svg + svg {
    margin-left: $spacing;
  }
}

.popup-biography__content__label {
  @extend %fw-bold;

  margin-top: $spacing * 2;
  color: $c-green-dark;
  font-size: 1.6rem;
}

.popup-biography__content__text {
  margin-top: $spacing / 2;
  color: $c-green-dark;
  font-size: 1.6rem;
  line-height: 1.8;
}

.popup-biography__content__link {
  margin-top: $spacing;
}
