
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












.slideshow-button {
  display: grid;
  place-items: center;
  width: 5rem;
  height: 5rem;
  background-color: $c-white;
  border: 1px solid $c-green-dark;
  border-radius: 6.5rem;
  transition: opacity 0.1s $ease-out;
  cursor: pointer;
  color: $c-green-dark;

  &:active {
    opacity: 0.6;
  }

  &.dark {
    color: $c-white;
    background-color: $c-green-dark;
  }
}

.slideshow-button__icon {
  width: 15px;
  height: 15px;
  fill: currentColor;
}
