
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









.btn {
  text-decoration: none;
  cursor: pointer;
}

.btn__label {
  @extend %fw-bold;

  display: inline-block;
  padding: 1.5rem 4rem;
  color: $c-white;
  font-family: $ff-alt;
  background-color: $c-green-dark;
  transition: background-color 0.2s ease-out;

  :hover > & {
    background-color: $c-green-light;
  }
}
