
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































$slide-width: 935px;
$slide-max-width: 70vw;

.gallery-slideshow {
  position: relative;
}

.gallery__item {
  padding: 0 30px;
}

.gallery__item__inner {
  display: grid;
  place-items: center;
  width: $slide-width;
  max-width: $slide-max-width;
  background: $c-gray-lighter;
}

.gallery__item__picture {
  width: 100%;
  height: 300px;
  object-fit: cover;

  @include mq(s) {
    height: 400px;
  }

  @include mq(l) {
    height: 522px;
  }
}

.gallery__item__caption-outer {
  @extend %caption-outer;
}

.gallery__item__caption {
  @extend %caption;
}

.gallery-controls__item,
[class*='gallery-controls__item--'] {
  position: absolute;
  z-index: 1;
  top: 50%;

  &[class*='--prev'] {
    left: max(
      calc((100vw - #{$slide-max-width}) / 2),
      calc((100vw - #{$slide-width}) / 2)
    );
    transform: translate(-65%, -50%);
  }

  &[class*='--next'] {
    right: max(
      calc((100vw - #{$slide-max-width}) / 2),
      calc((100vw - #{$slide-width}) / 2)
    );
    transform: translate(65%, -50%) rotate(180deg);
  }
}

.gallery__caption {
  width: $slide-width;
  max-width: $slide-max-width;
  margin-top: 20px;
  margin-right: auto;
  margin-left: auto;
  color: $c-gray-dark;
  font-size: 14px;
  text-align: center;

  &:empty {
    display: none;
  }

  @include mq(s) {
    margin-top: 30px;
  }

  @include mq(l) {
    margin-top: 38px;
  }
}
