
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

































.contact-item {
  display: flex;
  max-width: 400px;
  line-height: 1.3em;
}

.contact-item__icon-outer {
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: $spacing * 0.75;
}

.contact-item__icon {
  width: 20px;
  fill: $c-green-light;
}

.contact-item__headline {
  @extend %text-uppercase;

  color: $c-green-light;
}

.contact-item__text,
.contact-item__link {
  @extend %fw-bold;

  display: block;
  color: $c-white;

  .is-white & {
    color: $c-green-dark;
  }

  .contact-item__headline + & {
    margin-top: $spacing / 4;
  }
}

/* stylelint-disable-next-line */
.contact-item__link {
  @extend %text-nodecoration;

  transition: color 0.2s ease-out;

  &:hover {
    color: $c-green-light;
  }
}
