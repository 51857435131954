
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































































































.share {
  display: flex;
  align-items: center;
}

.share__label {
  @extend %fw-bold;

  color: $c-green-dark;
  font-size: 1.6rem;
  line-height: 1em;
  white-space: nowrap;

  .share.is-light & {
    color: $c-white;
  }
}

.share__list {
  @extend %list-nostyle;

  display: flex;
  align-items: center;
  margin-left: $spacing;
}

.share__item {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;

  & + & {
    margin-left: $spacing / 2;
  }
}

.share__item__link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}

.share__item__link__icon {
  width: 100%;
  fill: $c-green-dark;
  transition: fill 0.2s ease-out;

  .share.is-light & {
    fill: $c-white;
  }

  .share__item:hover & {
    fill: $c-green-light;
  }

  .share.is-light .share__item:hover & {
    fill: $c-green-light;
  }
}
