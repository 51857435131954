
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























































































































































































































































































































.card {
  // min-width: 27rem;
  height: 40rem;

  .card-grid &,
  .simple-card-grid & {
    @include mq($until: m) {
      display: flex;
      height: 15rem;

      .card__picture-outer {
        flex-basis: 30%;
        height: 15rem;
        min-height: 0;
        margin-right: $spacing;
      }
    }
  }

  .case-studies & {
    height: auto;
    min-height: 40rem;
    padding-bottom: 8rem;
  }
}

.card,
[class*='card--'] {
  @include bottom-dashed-line;

  position: relative;
  display: block;
  margin-bottom: $spacing * 4;
  padding-bottom: 2rem;
  text-decoration: none;
  cursor: pointer;

  &[class*='--video'] {
    padding-bottom: 0;
    border: 0;
  }

  .results-grid__highlights &,
  .card-grid__item--highlight & {
    display: block;
    height: 71.5rem;
    border: 0;

    @include mq($until: m) {
      height: auto;
    }
  }

  .simple-card-grid__item--highlight & {
    display: block;
    height: 65rem;
    border: 0;

    .case-studies & {
      height: auto;
      padding-bottom: 8rem;
    }

    @include mq($until: m) {
      height: auto;
    }
  }

  .flexible & {
    position: relative;
    width: col(14);
    margin: 0 col(1);
    cursor: pointer;
  }

  &:hover {
    .card__picture {
      transform: scale(1.1);
    }
  }
}

.card-inner {
  [class*='card--'][class*='--video'] & {
    position: absolute;
    bottom: 2rem;
    left: 2rem;
    color: $c-white;
  }
}

.card__picture-outer {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: 100%;
  min-height: 19rem;
  background-color: $c-gray-lighter;

  [class*='card--'][class*='--video'] & {
    height: 100%;
    background-image: linear-gradient(
      0deg,
      rgba($c-black, 0.6) 0%,
      rgba($c-black, 0) 80%
    );
  }

  [class*='card--'][class*='--biography'] & {
    overflow: hidden;
    width: 6rem;
    min-width: initial;
    height: 6rem;
    min-height: initial;
    margin-top: 1rem;
    border-radius: 6rem;

    .card-grid__item--highlight & {
      width: 20rem;
      height: 20rem;
      border-radius: 20rem;
    }
  }

  .results-grid__highlights &,
  .card-grid__item--highlight &,
  .simple-card-grid__item--highlight & {
    min-height: 36rem;
  }
}

.card__picture {
  @include image-fit;

  transition: transform 0.3s ease-out;
}

.card__category {
  @extend %text-uppercase;
  @extend %fw-bold;
  @include get-color;

  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.7rem $spacing / 2;
  color: $c-white;
  font-family: $ff-alt;
  font-size: 1.2rem;
  line-height: 1;
  text-decoration: none;
  background-color: $c-gray-darker;

  [class*='card--'][class*='--video'] & {
    position: initial;
  }

  [class*='card--'][class*='--biography'] & {
    position: initial;
  }
}

.card__title {
  margin-top: 0.5em;
  margin-bottom: 1em;

  [class*='card--'][class*='--video'] & {
    color: $c-white;
  }

  [class*='card--'][class*='--case-study'] & {
    margin-bottom: 1rem;
    color: $c-green-dark;
    font-size: 2rem;
    font-weight: 700;

    .simple-card-grid__item--highlight & {
      font-size: 3.6rem;
    }
  }

  .results-grid__highlights &,
  .card-grid__item--highlight &,
  .simple-card-grid__item--highlight & {
    font-size: 3.6rem;

    @include mq($until: m) {
      font-size: 2.4rem;
    }
  }
}

.card__subtitle {
  color: $c-gray-dark;
}

.card__footer {
  position: absolute;
  bottom: 0;
  width: 100%;

  @include mq($until: m) {
    position: relative;
  }
}

.card__footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.2rem;
}

.card__footer__date {
  @extend %fw-bold;
  @extend %text-uppercase;

  font-size: 1.2rem;
  letter-spacing: 0.1rem;
}

.card__footer__location {
  @extend %text-uppercase;

  color: $c-gray-dark;
  font-size: 1.3rem;
  letter-spacing: 0.15rem;
}

.card__footer__logo-outer {
  margin-left: auto;
}

.card__footer__logo__link {
  text-decoration: none;
}

.card__company {
  color: $c-gray-dark;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;

  .simple-card-grid__item--highlight & {
    font-size: 2.4rem;
  }
}

.card__country {
  color: $c-green-dark;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  text-transform: uppercase;
}

.card__year {
  margin-bottom: 1.5rem;
  color: $c-gray-dark;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 2rem;
  text-transform: uppercase;
}
