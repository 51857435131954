
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







.keypoint {
  @extend %text-center;
  @extend %ff-alt;
  @extend %fw-bold;

  color: $c-green-dark;
  font-size: 2.4rem;
  line-height: 1.2em;
}
