
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




















.contact-related {
  position: relative;

  &::after {
    @include bottom-dashed-line(rgba($c-green-light, 0.2));

    content: '';
    position: absolute;
    bottom: 0;
    left: col(1);
    width: col(14);
    height: 2px;

    @include mq(m) {
      left: col(6);
      width: col(9);
    }
  }

  @include mq(m) {
    display: flex;
  }
}

.contact-related__title-outer {
  flex-shrink: 0;
  width: col(14);
  margin: 0 col(1);

  @include mq(m) {
    width: col(4);
    margin: 0 0 0 col(1);
  }
}

.contact-related__title {
  @extend %fw-bold;

  color: $c-white;
  font-size: 1.6rem;
  line-height: 1.2em;
}

.contact-related__list {
  @extend %list-nostyle;

  width: col(14);
  margin: 0 col(1);
  color: $c-white;
  font-size: 1.4rem;
  line-height: 1.2;

  .contact-related__title-outer + & {
    @include mq($until: m) {
      margin-top: $spacing;
    }
  }

  @include mq(s) {
    display: flex;
    flex-wrap: wrap;
  }

  @include mq(m) {
    width: col(9);
    margin: 0 0 0 col(1);
  }
}

.contact-related__item {
  @include mq($until: s) {
    & + & {
      margin-top: $spacing * 1.5;
    }
  }

  @include mq(s) {
    width: calc(50% - #{$spacing / 2});

    &:nth-child(even) {
      margin-left: $spacing;
    }

    &:nth-child(n + 3) {
      margin-top: $spacing;
    }
  }
}
