
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























.legal {
  color: $c-gray-medium;
  font-size: 1.3rem;

  @include mq(xl) {
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    background-image: linear-gradient(
      to right,
      $c-gray-light 23%,
      rgba($c-black, 0) 0%
    );
    background-position: top;
    background-repeat: repeat-x;
    background-size: 10px 2px;
  }
}

.legal__list {
  @extend %list-nostyle;
  @extend %text-center;

  padding: $spacing 0;

  @include mq(xl) {
    display: flex;
    padding: $spacing * 2 0;
  }
}

.legal__item {
  @include mq(xl) {
    & + & {
      margin-left: $spacing * 2;
      font-size: 1.3rem;
    }
  }
}

.legal__item__link {
  @extend %text-nodecoration;
  @extend %button-nostyle;

  color: $c-gray-medium;
  transition: color 0.2s ease-out;

  &:hover {
    color: $c-green-dark;
  }
}
