
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































































.pictures {
  @include get-all-space;

  position: fixed;
  z-index: 9999;
  overflow: hidden;
  background-color: $c-white;
}

.pictures__bar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.pictures__bar__btn {
  @extend %button-nostyle;
  @extend %text-uppercase;
  @extend %fw-bold;

  color: $c-green-dark;
  font-size: 1.4rem;
  line-height: 1.2em;
  letter-spacing: 0.1rem;
  transition: color 0.2s ease-out;

  &:hover {
    color: $c-green-light;
  }
}

.pictures-inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 80px);
}

.pictures__container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 80px);
}

.pictures__gallery {
  width: 100%;
  padding-top: 0 !important; // stylelint-disable-line declaration-no-important
  padding-bottom: 0 !important; // stylelint-disable-line declaration-no-important
}
