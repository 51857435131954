
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































.universe-switcher {
  @extend %ff-alt;
  @extend %fw-bold;

  position: relative;
  width: 240px;
  font-size: 1.6rem;
  line-height: 1.2em;
}

.universe-switcher-inner {
  position: relative;
  padding: $spacing;
  transition: color 0.1s ease-out, border-color 0.1s ease-out;
  cursor: pointer;
  user-select: none;

  .is-open & {
    height: auto;
    border: 1px solid $c-gray-light;

    .ie & {
      overflow: visible;
    }
  }
}

.universe-switcher__label {
  overflow: hidden;
  padding-right: $spacing * 3;
  color: $c-green-dark;
  white-space: nowrap;

  .universe-switcher.is-open & {
    color: $c-green-light;
  }
}

.universe-switcher__items {
  @include scrollbars(2px, $c-black, $c-white);

  position: absolute;
  z-index: 9;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: scroll;
  width: 100%;
  max-height: 300px;
  background-color: $c-white;
  border: 1px solid $c-gray-light;
  border-top: 0;
}

.universe-switcher__item {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  height: 50px;
  padding: 0 $spacing * 2.5 0 $spacing;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    color: $c-white;
    background-color: $c-green-dark;
  }

  &.is-selected {
    padding-left: $spacing * 1.5;
    color: $c-green-dark;
    background-color: $c-green-light;
    pointer-events: none;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 20px;
      width: 2px;
      height: 1em;
      background-color: $c-green-dark;
      transform: translateY(-50%);
    }
  }
}

.universe-switcher__item__arrow {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: $spacing * 3.5;
  display: block;
  width: 24px;
  height: 24px;
  fill: $c-green-dark;
  user-select: none;
  pointer-events: none;
  transform: translateY(-50%) rotate(0);
  transition: fill 0.2s ease-out, transform 0.2s ease-out;
  will-change: transform;

  .universe-switcher.is-open & {
    transform: translateY(-50%) rotate(180deg);
  }

  .universe-switcher.is-selected & {
    transform: translateY(-50%) translateX(-30px);
  }

  .universe-switcher.is-open.is-selected & {
    transform: translateY(-50%) translateX(-30px) rotate(180deg);
  }
}
