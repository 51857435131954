
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























.read-more {
  @extend %text-nodecoration;
  @extend %ff-alt;

  position: relative;
  display: block;
  width: 100%;
  margin-top: $spacing;

  @include mq(l) {
    max-width: 540px;
  }
}

.read-more__headline {
  @extend %fw-bold;

  color: $c-gray-dark;
  font-size: 1.6rem;
  line-height: 1.2em;
  transition: color 0.2s ease-out;

  .read-more:hover & {
    color: $c-green-light;
  }

  @include mq(xs) {
    font-size: 1.7rem;
  }

  @include mq(s) {
    font-size: 1.8rem;
  }
}

.read-more-inner {
  display: flex;
  flex-direction: column;
  margin-top: $spacing / 2;

  @include mq(xs) {
    flex-direction: row;
  }
}

.read-more__picture-outer {
  position: relative;
  width: 100%;
  background-color: $c-gray-medium;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (3 / 4) * 100%;

    @include mq(xs) {
      padding-top: (1 / 1) * 100%;
    }
  }

  @include mq(xs) {
    flex-shrink: 0;
    align-self: flex-start;
    width: 35%;
    margin-right: $spacing * 1.5;
  }
}

.read-more__picture {
  @include image-fit;

  transition: opacity 0.2s ease-out;

  .read-more:hover & {
    opacity: 0.75;
  }
}

.read-more__category {
  @extend %text-uppercase;
  @extend %fw-bold;
  @include get-color;

  position: absolute;
  top: 0;
  left: 0;
  padding: $spacing / 2.5 $spacing / 2;
  color: $c-white;
  font-size: 1.2rem;
  line-height: 1em;
  white-space: nowrap;
}

.read-more__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: $spacing / 4;
  border-bottom: 1px dashed $c-black;
  transition: border-bottom 0.2s ease-out;

  .read-more:hover & {
    border-bottom-color: $c-green-light;
  }

  @include mq($until: xs) {
    margin-top: $spacing;
  }
}

.read-more__content__title {
  @extend %fw-bold;

  flex-grow: 1;
  width: 100%;
  font-size: 1.6rem;
  line-height: 1.4em;

  @include mq(xs) {
    font-size: 1.8rem;
  }

  @include mq(s) {
    font-size: 2rem;
  }
}

.read-more__content__date {
  @extend %fw-bold;
  @extend %text-uppercase;

  margin-top: $spacing * 2;
  font-size: 1.1rem;
  line-height: 1.2em;

  @include mq(xs) {
    font-size: 1.2rem;
  }

  @include mq(s) {
    font-size: 1.3rem;
  }
}

.read-more__content__title,
.read-more__content__date {
  color: $c-green-dark;
  transition: color 0.2s ease-out;

  .read-more:hover & {
    color: $c-green-light;
  }
}
