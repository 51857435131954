
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



































.quote {
  @include default-margin(1.33);

  position: relative;
  color: $c-green-dark;
  font-size: 2.2rem;
  line-height: 1.5;

  @include mq(s) {
    font-size: 2.8rem;
  }
}

.quote__text,
.quote__author {
  width: col(10);
  margin-left: col(3);
}

.quote__text {
  position: relative;
  text-align: center;

  @include mq(s) {
    text-align: left;
  }
}

.quote__text__icon {
  content: '';
  position: relative;
  z-index: -1;
  display: inline-block;
  width: 36px;
  height: 36px;
  margin-right: -36px;
  fill: $c-gray-lighter;
  user-select: none;
  transform: translate(-50%, -10px);
}

.quote__author {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $spacing * 2;

  @include mq(s) {
    justify-content: flex-start;
  }
}

.quote__author__picture-outer {
  position: relative;
  overflow: hidden;
  width: 64px;
  margin-right: $spacing;
  border-radius: 50%;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (1 / 1) * 100%;
  }
}

.quote__author__picture {
  @include image-fit;
}

.quote__author__name,
.quote__author__job {
  @extend %fw-bold;

  font-size: 1.4rem;
  line-height: 1.2em;

  @include mq(s) {
    font-size: 1.6rem;
  }
}

.quote__author__job {
  margin-top: $spacing / 4;
  color: $c-gray-medium;
}
