
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































.individual-signatories {
  @include default-margin;
}

.individual-signatories-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.individual-signatories__header {
  width: col(14);
  margin: 0 col(1);

  @include mq(m) {
    display: flex;
    justify-content: space-between;
  }
}

.individual-signatories__title {
  font-weight: 400;
  color: $c-green-dark;
}

.individual-signatories__actions {
  margin-left: col(1);
  margin-right: col(1);
  width: col(14);
  display: flex;
  justify-content: flex-end;
}

.btn {
  text-decoration: none;
  cursor: pointer;
}

.btn__label {
  @extend %fw-bold;

  display: inline-block;
  padding: 1.5rem 4rem;
  color: $c-white;
  font-family: $ff-alt;
  background-color: $c-green-dark;
  transition: background-color 0.2s ease-out;

  :hover > & {
    background-color: $c-green-light;
  }
}
