
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































































































.video {
  @include default-margin;

  @include mq($until: xxl) {
    width: col(14);
    margin-right: col(1);
    margin-left: col(1);
  }
}

.video-outer {
  @include mq(m) {
    display: flex;
    justify-content: space-between;
  }
}

.video-inner {
  position: relative;
  cursor: pointer;
  background-color: $c-green-dark;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }
}

.video__title {
  @extend %text-center;

  margin-bottom: $spacing * 2.5;
  color: $c-green-dark;
}

.video__highlight {
  // flex-basis: 42%;
  width: 100%;

  &.is-solo {
    .video__icon {
      @include center-xy;

      width: 60px;
      height: 60px;
    }
  }

  @include mq($until: m) {
    margin: $spacing * 3 0;
  }
}

.video__items {
  flex-shrink: 0;
  flex-basis: 51%;
  width: 100%;

  @include mq(m) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: col(1);
  }
}

.video__item {
  position: relative;
  flex-basis: 44%;
  width: 100%;
  cursor: pointer;
  margin-bottom: $spacing * 3;
  background-color: $c-green-dark;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (9 / 16) * 100%;
  }

  .video__icon {
    right: $spacing;
    left: inherit;

    @include mq(s) {
      right: $spacing * 1.5;
    }
  }
}

.video__poster {
  @include get-all-space;

  background-color: $c-black;
}

.video__poster__picture {
  @include image-fit;

  opacity: 0.3;
  transition: opacity 0.2s ease-out;

  .video-inner:hover & {
    opacity: 0.5;
  }
}

.video__content {
  @extend %ff-alt;

  position: absolute;
  z-index: 2;
  bottom: $spacing;
  left: $spacing;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(100% - #{$spacing * 2});
  max-width: 340px;
  color: $c-white;

  @include mq(m) {
    bottom: $spacing * 2;
    left: $spacing * 2;
    width: calc(100% - #{$spacing * 4});
    max-width: 600px;
  }

  @include mq(l) {
    bottom: $spacing * 3;
    left: $spacing * 3;
    width: calc(100% - #{$spacing * 6});
    max-width: 600px;
  }
}

.video__content__category {
  @extend %text-uppercase;
  @extend %fw-bold;

  padding: $spacing / 2;
  font-size: 1.2rem;
  line-height: 1em;
  white-space: nowrap;
  background-color: $c-green-light;
}

.video__content__title {
  @extend %fw-bold;

  margin-top: $spacing;
  font-size: 2.4rem;
  line-height: 1.2em;

  @include mq(m) {
    font-size: 4.2rem;
    line-height: 1.2em;
  }
}

.video__icon {
  position: absolute;
  width: 40px;
  height: 40px;
  fill: $c-white;
  border: 2px solid $c-white;
  border-radius: 50%;
  transition: opacity 0.2s ease-out;

  @include mq($until: s) {
    top: $spacing;
    right: $spacing;
  }

  @include mq(m) {
    top: $spacing * 1.5;
    left: $spacing * 1.5;
  }

  // @include mq(m) {
  //   width: 100px;
  //   height: 100px;
  // }
}

.video__poster__picture__caption-outer {
  @extend %caption-outer;
}

.video__poster__picture__caption {
  @extend %caption;
}
