
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























.social-network {
  display: flex;
  margin: 0 -1rem;
  text-align: center;
}

.social-network__items {
  display: flex;
  align-items: center;
  list-style: none;
}

.social-network__item {
  & + & {
    margin-left: 2rem;
  }
}

.social-network__item__icon {
  fill: $c-green-light;
  transition: fill 0.2s ease-out;

  .social-network__item-inner:hover & {
    fill: $c-green-dark;
  }
}
