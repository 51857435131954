
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































































.socials {
  @include default-margin;

  background-color: $c-gray-lighter;

  .ctas + & {
    margin-top: 0;
  }

  &.redux {
    width: col(14);
    margin-right: col(1);
    margin-left: col(1);
  }
}

.socials-inner {
  @include mq(s) {
    display: flex;
  }
}

.socials__title {
  @extend %fw-normal;
  @extend %ff-alt;

  width: col(14);
  margin-left: col(1);
  color: $c-green-dark;
  font-size: 2.4rem;
  line-height: 1.1em;

  strong {
    @extend %fw-bold;
  }

  @include mq(s) {
    width: col(7);
  }

  @include mq(l) {
    width: col(8);
    font-size: 2.8rem;
  }

  @include mq(xxl) {
    width: col(7);
    font-size: 3.2rem;
  }
}

.socials__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: col(14);
  margin-top: $spacing;
  margin-left: col(1);

  @include mq(s) {
    justify-content: flex-end;
    width: col(6);
    margin-top: 0;
  }

  @include mq(l) {
    width: col(5);
  }

  @include mq(xxl) {
    width: col(6);
  }
}

.socials__item {
  @include mq($until: xxl) {
    width: calc(50% - #{$spacing / 4});

    &:nth-child(n + 3) {
      margin-top: $spacing;
    }
  }

  @include mq($until: s) {
    &:nth-child(odd) {
      margin-right: $spacing / 2;
    }
  }

  @include mq(s, xxl) {
    &:nth-child(even) {
      margin-left: $spacing / 2;
    }
  }

  @include mq(xxl) {
    width: calc(33.33% - #{$spacing / 3});
    padding: $spacing / 2 $spacing * 2 $spacing / 2 0;

    &:nth-child(3n + 2),
    &:nth-child(3n + 3) {
      margin-left: $spacing / 2;
    }

    &:nth-child(n + 4) {
      margin-top: $spacing * 0.75;
    }
  }
}

.socials__item-inner {
  @extend %text-nodecoration;

  display: flex;
  align-items: center;
}

.socials__item__icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: $spacing;
  fill: $c-gray-medium;
  transition: fill 0.2s ease-out;

  .socials__item-inner:hover & {
    fill: $c-green-light;
  }
}

.socials__item__label {
  @extend %ff-alt;
  @extend %fw-bold;

  color: $c-green-dark;
  font-size: 1.6rem;
  line-height: 1.2em;
  transition: color 0.2s ease-out;

  .socials__item-inner:hover & {
    color: $c-green-light;
  }

  @include mq(s) {
    font-size: 1.8rem;
  }

  @include mq(xl) {
    font-size: 2rem;
  }
}
