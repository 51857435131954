
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































































.signatories-grid {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
  width: col(14);
  margin: $spacing * 2 col(1);

  @include mq(m) {
    margin: $spacing * 3.5 col(1);
  }
}

.signatories-grid__item {
  position: relative;
  width: 100%;

  @include mq($until: xs) {
    & + & {
      margin-top: $spacing * 2;
    }
  }

  @include mq(xs, s) {
    width: calc(50% - #{$spacing * 2 / 2});

    &:nth-child(2n + 2) {
      margin-left: $spacing * 2;
    }

    &:nth-child(n + 3) {
      margin-top: $spacing * 2;
    }
  }

  @include mq(s, l) {
    width: calc(33.33% - #{$spacing * 3 * 2 / 3});

    &:nth-child(3n + 2),
    &:nth-child(3n + 3) {
      margin-left: $spacing * 3;
    }

    &:nth-child(n + 4) {
      margin-top: $spacing * 3;
    }
  }

  @include mq(l) {
    width: calc(25% - #{$spacing * 4 * 3 / 4});

    &:nth-child(4n + 2),
    &:nth-child(4n + 3),
    &:nth-child(4n + 4) {
      margin-left: $spacing * 4;
    }

    &:nth-child(n + 5) {
      margin-top: $spacing * 4;
    }
  }
}

.signatories-grid__item__picture-outer {
  position: relative;
  width: 100%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23D0D0D0FF' stroke-width='4' stroke-dasharray='2%2c 15' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");

  &::before {
    content: '';
    display: block;
    padding-top: calc(18 / 25 * 100%);
  }

  .is-individual & {
    background-image: none;

    &::before {
      padding-top: 100%;
    }
  }
}

.signatories-grid__item__picture,
[class*='signatories-grid__item__picture--'] {
  @include image-fit(contain, center);

  top: $spacing * 2;
  left: $spacing * 2;
  width: calc(100% - #{$spacing * 4});
  height: calc(100% - #{$spacing * 4});

  &[class*='--placeholder'] {
    top: 0;
    left: 1rem;
    width: calc(100% - 2rem);
    height: 100%;
  }

  .is-individual & {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.signatories-grid__content {
  margin-top: $spacing;
  font-family: $ff-alt;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 3rem;
}
.signatories-grid__content__title {
  color: $c-green-dark;
}
.signatories-grid__content__type {
  color: $c-gray-medium;
}
.signatories-grid__content__country {
  font-size: 1.3rem;
  font-weight: 400;
  color: $c-green-dark;
  text-transform: uppercase;
}

.signatories-grid__item__link {
  @extend %text-nodecoration;
  @include get-all-space;
}
