
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































































































.package {
  position: relative;
  margin-top: $spacing * 2;

  @include mq(l) {
    margin-top: 0;
  }
}

.package__head {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 13.5rem;
  border: 1px solid $c-gray-lighter;
  border-radius: 0.5rem 0.5rem 0 0;
}

.package__head__title {
  color: $c-green-light;
  font-family: $ff-alt;
  font-size: 2rem;
  font-weight: 900;

  img {
    width: 17.5rem;
    height: auto;
  }
}

.package__body {
  padding: 2rem 4rem;
  background-color: $c-white;
  border: 1px solid $c-gray-lighter;
  border-top: 0;
  border-radius: 0 0 0.5rem 0.5rem;
}

.package__body__row {
  @include bottom-dashed-line;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8rem;

  &:last-child {
    background: none;
  }

  @include mq(l) {
    justify-content: center;
  }
}

.package__body__row__label {
  @extend %fw-bold;

  color: $c-green-light;

  .small {
    color: $c-gray-lighter;
  }

  @include mq(l) {
    display: none;
  }
}

.package__body__row__value {
  @extend %fw-bold;

  color: $c-green-dark;
  font-size: 2rem;

  .icon,
  [class*='icon--'] {
    fill: $c-transparent;
    stroke: $c-gray-lighter;

    &.true {
      width: 4rem;
      height: 4rem;
      padding: 1rem;
      stroke: $c-green-dark;
      border: 1px solid $c-gray-lighter;
      border-radius: 5rem;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 2rem;

  @include mq($until: m) {
    display: none;
  }
}

/* Tooltip text */
.tooltip .tooltip__content {
  position: absolute;
  z-index: 1;
  top: -2rem;
  left: calc(100% + 2rem);
  visibility: hidden;
  width: 50rem;
  padding: 3rem 5rem;
  color: $c-green-dark;
  background-color: $c-white;
  border-left: 1rem solid $c-green-light;
  border-radius: 0.6rem;
  box-shadow: 0 2rem 4rem rgba($c-black, 0.2);
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltip__content {
  visibility: visible;
}

.tooltip__title {
  margin-bottom: $spacing * 0.25;
  font-family: $ff-alt;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.4rem;
}

.tooltip__text {
  font-size: 1.3rem;
  line-height: 2.2rem;
}

.package__footer-outer {
  position: relative;
  bottom: 3px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 3rem 4rem;
  color: $c-green-dark;
  font-family: $ff-alt;
  background-color: $c-gray-lighter;
  // transform: translateY(100%);
  border-radius: 0 0 0.5rem 0.5rem;
}

.package__footer__value {
  @extend %fw-bold;
}
