
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































.collapse {
  @include bottom-dashed-line;
}

.collapse__header {
  @extend %fw-bold;
  @extend %ff-alt;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing * 1.5 0 $spacing;
  color: $c-green-dark;
  user-select: none;
  font-size: 1.6rem;
  line-height: 1.4;
  transition: color 0.2s ease-out;
  cursor: pointer;

  .collapse.is-open &,
  .collapse:hover & {
    color: $c-green-light;
  }
}

.collapse__header__icon {
  flex-shrink: 0;
  overflow: visible;
  width: 20px;
  height: 20px;
  margin-left: auto;
  fill: $c-green-dark;
  transition: fill 0.2s ease-out;

  .collapse__header__title + & {
    margin-left: $spacing;
  }

  .collapse.is-open &,
  .collapse:hover & {
    fill: $c-green-light;
  }
}

.collapse__text {
  overflow: hidden;
}

.collapse__text-inner {
  margin-bottom: $spacing;
}
