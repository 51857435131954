
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















.expertise {
  @include default-margin;

  background-color: $c-green-dark;
}

.expertise-inner {
  display: flex;
  justify-content: space-between;
  margin-right: col(1);
  margin-left: col(1);

  @include mq($until: m) {
    flex-direction: column;
  }
}

.expertise__title {
  color: $c-white;

  @include mq($until: m) {
    text-align: center;
  }
}

.expertise__main {
  width: col(9);
  padding-right: col(2);

  @include mq($until: m) {
    width: 100%;
    margin-top: $spacing * 2;
    padding-right: 0;
  }
}

.expertise__main__text {
  margin-bottom: $spacing;
  color: rgba($c-white, 0.6);

  @include mq(m) {
    margin-bottom: $spacing * 3;
  }
}

.expertise__main__collapse {
  .collapse__header,
  .collapse__text-inner a {
    color: $c-white;
  }

  .collapse__header__icon {
    fill: $c-white;
  }
}
