
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































.related-link {
  @extend %text-nodecoration;

  display: flex;
  align-items: center;
  cursor: pointer;
}

.related-link__icon-outer {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: $spacing;
}

.related-link__icon {
  @include get-all-space;

  fill: $c-white;
  transition: fill 0.2s ease-out;

  .related-link:hover & {
    fill: $c-green-light;
  }
}

.related-link__extension {
  @extend %fw-bold;
  @extend %text-uppercase;
  @include center-xy;

  z-index: 2;
  color: $c-white;
  font-size: 0.6rem;
  transition: color 0.2s ease-out;

  .related-link:hover & {
    color: $c-green-light;
  }
}

.related-link__label {
  @extend %fw-bold;

  color: $c-white;
  font-size: 1.4rem;
  line-height: 1.5em;
  transition: color 0.2s ease-out;

  .related-link:hover & {
    color: $c-green-light;
  }
}
