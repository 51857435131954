
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


























.keypoints__list {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: col(14);
  max-width: 1080px;
  margin: 0 auto !important; // stylelint-disable-line declaration-no-important

  &.col-even {
    margin: 0 col(2);
  }
}

.keypoints__item {
  width: 100%;
  padding: 0 $spacing * 2;

  @include mq($until: xs) {
    & + & {
      margin-top: $spacing;
    }
  }

  @include mq(xs, s) {
    width: 50%;

    &:nth-of-type(even) {
      border-left: 1px solid $c-green-light;
    }

    &:nth-of-type(n + 3) {
      margin-top: $spacing * 2;
    }
  }

  @include mq(s) {
    .col-even & {
      width: calc(100% / 2);

      &:nth-of-type(2n + 1) {
        border-right: 1px solid $c-green-light;
      }

      &:nth-of-type(n + 3) {
        margin-top: $spacing * 4;
      }
    }

    .col-odd & {
      width: 33.33%;

      &:nth-of-type(3n + 2),
      &:nth-of-type(3n + 3) {
        border-left: 1px solid $c-green-light;
      }

      &:nth-of-type(n + 4) {
        margin-top: $spacing * 4;
      }
    }
  }
}
