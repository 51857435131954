/* stylelint-disable no-descending-specificity */
/* stylelint-disable declaration-no-important */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable selector-max-combinators */
$bp: m;

.cookiebar {
  --cookiebar-c-light: $c-white;
  --cookiebar-c-dark: $c-white;
  --cookiebar-c-gray: $c-white;
  --cookiebar-c-text: $c-green-dark;
  --cookiebar-c-primary: $c-white;
  --cookiebar-c-primary-list: $c-white;
  --cookiebar-c-primary-light: $c-white;
  --cookiebar-c-secondary: $c-white;
  --cookiebar-c-secondary-light: $c-white;

  position: relative;
  z-index: 2000;

  &.is-editing,
  &.is-fullscreen {
    z-index: 2001;
  }

  form {
    background-color: transparent;
  }

  .cookiebar-selection-outer {
    max-width: 600px;
  }

  .cookiebar__title {
    display: block;
    margin: 0 0 1rem;
    margin-bottom: 20px;
    color: $c-green-dark;
    font-family: $ff-alt;
    font-size: 32px;
    font-weight: 900;
  }

  .cookiebar__text {
    color: $c-green-dark;
    font-family: $ff-default;
    font-size: 14px;
  }

  .cookiebar-toggle label {
    position: relative;
    display: block;
    width: 70px;
    height: 30px;
    text-indent: -9999px;
    background-color: $c-white;
    border: 1px solid #a0a0a0;
    border-color: $c-black;
    border-radius: 100px;
    cursor: pointer;
  }

  .cookiebar-toggle label::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 5px;
    width: 20px;
    height: 20px;
    background-color: $c-green-dark;
    border-radius: 20px;
    transition: 0.3s;
  }

  .cookiebar-toggle input:checked + label::after,
  .cookiebar-toggle input:disabled + label::after {
    left: calc(100% - 4px);
    transform: translateX(-100%);
  }

  .cookiebar-toggle input:checked + label::after {
    background-color: $c-white;
  }

  .cookiebar-toggle input:checked + label {
    background-color: $c-green-light;
  }

  .cookiebar-toggle input:checked + label::after {
    background-color: $c-green-dark !important;
  }

  .cookiebar-toggle input:disabled + label {
    background-color: $c-gray-medium;
  }

  .cookiebar-toggle input:disabled + label::after {
    background-color: $c-gray-medium;
  }

  .cookiebar__item__header {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  .cookiebar__item__header__title {
    margin: 0;
    margin-left: 10px;
    color: $c-green-dark;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.1;
    cursor: pointer;
  }

  .cookiebar__item__details {
    overflow: hidden;
    max-height: 0;
    font-family: $ff-default;
    border-bottom: 1px solid #a0a0a0;
    border-color: $c-black;
  }

  .cookiebar__item__details h3,
  .cookiebar__item__details .h3 {
    margin: 0;
    margin-bottom: 10px;
    color: $c-green-dark;
    font-size: 18px !important;
    font-weight: 700;
    line-height: 1.1;
  }

  .is-open .cookiebar__item__details {
    max-height: none !important;
  }

  .cookiebar__item:not(:last-child) {
    border: 0;
  }

  .cookiebar__item:last-child .cookiebar__item__details {
    border-bottom: 0;
  }

  .cookiebar__action,
  [class*='cookiebar__action--'] {
    padding: 0.75em 1.25em;
    color: $c-white;
    font-family: $ff-default;
    font-weight: 700;
    line-height: 1;
    text-transform: uppercase;
    background-color: $c-green-dark;
    border: 1px solid #404040;
    border-color: $c-green-dark;
    border-radius: 30px;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &[class*='--link'] {
      color: $c-green-dark;
      background-color: transparent;

      &::before {
        display: none;
      }
    }

    @include mq(m) {
      &[class*='--link'] {
        border: 0;
      }
    }
  }

  [class*='cookiebar__action--'][class*='--hollow'] {
    color: $c-green-dark;
    background-color: $c-white;
  }

  .cookiebar__action svg,
  [class*='cookiebar__action--'] svg {
    display: none;
    margin-left: 7px;
    stroke: $c-black;
  }

  .cookiebar-controls {
    position: fixed;
    right: 20px;
    bottom: 20px;
    max-width: 520px;
    margin-left: 20px;
    padding: 20px;
    background-color: $c-white;
    border: 1px solid $c-green-dark;
    opacity: 0;
    will-change: transform, opacity;

    [class*='cookiebar__action--'][class*='--link'] {
      padding: 0;
      font-size: 12px;
      border: 0;
    }
  }

  .cookiebar__actions__primary {
    flex-shrink: 0;
  }

  .cookiebar-controls__split {
    height: 1px;
    margin: 20px 0;
    background-color: $c-green-dark;
    border: 0;
  }

  .cookiebar-selection__content {
    color: $c-green-dark;
    background-color: $c-white;
  }

  .cookiebar-selection__content__text {
    margin-bottom: 40px;
    padding: 0 40px;
  }

  .cookiebar-selection__content__cookies {
    padding: 0 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $c-white;
    border-top: 1px solid $c-white;
  }

  .cookiebar__list {
    margin-top: 0;
  }

  .cookiebar-selection__footer {
    position: relative;
    flex-wrap: wrap;
    flex-shrink: 0;
    height: auto;
    padding: 20px;
    background-color: $c-white;
    border-top: 1px solid $c-green-dark;
  }

  .cookiebar-toggle {
    label {
      background-color: rgba($c-white, 0.2);
    }
  }

  .cookiebar__item__details p {
    margin-bottom: 1.2em;
  }

  .cookiebar-selection__content__cookies {
    @include mq($bp) {
      padding: 40px;
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .cookiebar-selection__footer {
    @include mq($bp) {
      padding: 40px;
    }
  }

  .cookiebar-selection__footer {
    button {
      width: 100%;
      margin: 0;
    }

    button:first-child {
      margin-bottom: 10px;
    }

    .cookiebar__actions__primary {
      button:first-child {
        margin-right: 10px;
      }
    }

    &::before {
      display: none;
    }

    @include mq(m) {
      flex-wrap: nowrap;

      button {
        width: auto;
      }

      button:first-child {
        margin-bottom: 0;
      }
    }

    @include mq($until: m) {
      .cookiebar__actions__primary {
        width: 100%;
      }
    }
  }
}
