
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































































.hero {
  position: relative;
}

.hero-inner {
  position: relative;
}

.hero__picture-outer {
  clip: rect(0, 0, 500px, 0);

  .hero.default & {
    position: relative;
    overflow: hidden;
    min-height: 240px;
    max-height: 500px;
    border-bottom: 1px solid $c-gray-lighter;

    &::before {
      content: '';
      display: block;
      padding-top: (1 / 2) * 100%;
    }
  }

  .hero.home &,
  .hero.shaded & {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: $c-black;
  }
}

.hero__picture {
  @include image-fit;

  transform: scale(1.3);

  .hero.shaded & {
    opacity: 0.5;
  }
}

.hero__picture__caption {
  @extend %caption-outer;
}

.hero__picture__caption-inner {
  @extend %caption;
}

.hero__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $c-green-dark;

  &::after {
    @include bottom-dashed-line;

    content: '';
    position: absolute;
    bottom: 0;
    left: col(1);
    width: col(14);
    height: 2px;
  }

  .hero.home &::after,
  .hero.shaded &::after {
    content: none;
  }

  .hero.default & {
    padding-top: $spacing * 2 !important; // stylelint-disable-line declaration-no-important
    padding-bottom: $spacing * 1.5 !important; // stylelint-disable-line declaration-no-important

    @include mq(m) {
      padding-top: $spacing * 4.5 !important; // stylelint-disable-line declaration-no-important
      padding-bottom: $spacing * 2 !important; // stylelint-disable-line declaration-no-important
    }

    @include mq(l) {
      padding-top: $spacing * 7 !important; // stylelint-disable-line declaration-no-important
      padding-bottom: $spacing * 3.5 !important; // stylelint-disable-line declaration-no-important
    }
  }

  .hero.home &,
  .hero.shaded & {
    @include mq(xl) {
      padding-top: $spacing * 8 !important; // stylelint-disable-line declaration-no-important
      padding-bottom: $spacing * 8 !important; // stylelint-disable-line declaration-no-important
    }
  }

  @include mq(s) {
    flex-direction: row;
  }
}

.hero__logo-outer {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: 140px;
  margin-left: col(1);
  background-color: $c-gray-lighter;

  &::before {
    content: '';
    display: block;
    padding-top: (9 / 16) * 100%;
  }

  &.no-picture {
    position: relative;
    transform: translateY(0%);
    height: 8rem;
    margin-bottom: 2rem;
  }

  @include mq(s) {
    width: 200px;
    margin-right: $spacing * 2;
  }
}

.hero__logo {
  @include image-fit(contain, center center);

  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
}

.hero__content-inner {
  @include mq($until: s) {
    width: col(14);
    margin-left: col(1);

    .hero__logo-outer + & {
      margin-top: $spacing;
    }
  }

  @include mq(s) {
    width: col(14);
    margin-left: col(1);

    .hero__logo-outer + & {
      margin-left: 0;
    }
  }
}

.hero__headline {
  @extend %fw-bold;
  @extend %text-uppercase;
  @extend %ff-alt;

  display: flex;
  align-items: flex-end;
  color: $c-gray-medium;
  font-size: 1.6rem;
  line-height: 1.2em;
}

.hero__headline__topic {
  color: $c-green-light;
}

.hero__headline__category {
  margin-left: 0.4em;
}

.hero__title {
  .hero__picture + &,
  .hero__headline + & {
    margin-top: $spacing;
  }

  .hero.home &,
  .hero.shaded & {
    color: $c-white;
  }

  // Prevent title from going below ticket for example
  &.max-width {
    max-width: 440px;

    @include mq(l) {
      max-width: 580px;
    }

    @include mq(xxl) {
      max-width: 800px;
    }
  }
}

.hero__subtitle {
  @extend %fw-bold;

  margin-top: $spacing * 1.5;
  color: $c-gray-dark;
}

.hero__date {
  @extend %fw-bold;
  @extend %text-uppercase;
  @extend %ff-alt;

  color: $c-gray-medium;
  font-size: 1.6rem;
  line-height: 1.2em;

  .hero__picture + &,
  .hero__headline + &,
  .hero__title + & {
    margin-top: $spacing;
  }
}

.hero__htmltext {
  @include owl;

  max-width: 540px;
  margin-top: $spacing;
  font-size: 1.8rem;
  line-height: 2em;

  .hero.home &,
  .hero.shaded & {
    color: $c-white;
  }
}

.hero__ticket-outer,
.hero__cta-outer {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;

  @include mq($until: m) {
    width: col(14);
    margin-top: $spacing;
  }

  @include mq(m) {
    position: absolute;
    bottom: -69px;
    left: 50%;
    justify-content: flex-end;
    width: 100%;
    transform: translateX(-50%);
  }
}

.hero__ticket,
.hero__cta {
  border: 1px solid $c-gray-lighter;

  @include mq(m) {
    position: absolute;
    right: col(1);
    bottom: 0;
    max-width: 350px;
  }
}

.hero__picture__logo {
  margin-top: $spacing * -2;
  display: block;
  width: 18rem;
  height: 8.6rem;
  background-color: $c-white;
  transform: translateY(-100%);
  padding: 1.5rem;

  .hero__picture__logo-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }

  img {
    @include image-fit(contain, center);
  }

  @include mq(m) {
    margin-top: $spacing * -4.5;
    width: 24rem;
    height: 11.5rem;
  }

  @include mq(l) {
    margin-top: $spacing * -7;
  }
}
