
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















.cta,
[class*='cta--'] {
  @extend %ff-alt;

  color: $c-green-dark;
  background-color: $c-green-light;

  &.dark {
    color: $c-green-light;
    background-color: $c-green-dark;
  }

  .redux & {
    color: $c-green-dark;
    background-color: transparent;

    &.dark {
      color: $c-green-light;
      background-color: transparent;
    }
  }
}

.cta-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: col(14);
  margin-left: col(1);

  .cta.redux & {
    width: col(6);
    margin-left: col(1, 8);
  }

  .ctas.redux & {
    padding-right: col(1/2, 8);
    padding-left: col(1/2, 8);
    color: $c-green-dark;
    background-color: $c-green-light;

    @include mq(m) {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &.dark {
      color: $c-green-light;
      background-color: $c-green-dark;
    }
  }
}

.cta__title {
  @extend %fw-normal;

  width: 100%;

  strong {
    @extend %fw-bold;

    display: block;
    color: $c-white;
  }

  .searchtool & {
    font-size: 4.8rem;
  }
}

.cta__link {
  flex-shrink: 0;
  margin-top: $spacing;

  .ctas.redux & {
    @include mq(m) {
      margin-top: 0;
    }
  }
}
