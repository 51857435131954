
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























.infos {
  @include default-margin(0.5);
}

.infos-inner {
  @include mq(s) {
    display: flex;
    align-items: flex-start;
  }
}

.infos__tags {
  width: col(14);

  @include mq($until: s) {
    margin: 0 col(1);
  }

  @include mq(s) {
    width: col(6);
    margin-left: col(1);
  }
}

.infos__meta {
  display: flex;
  flex-wrap: wrap;
  width: col(14);
  margin: 0 col(1);

  .infos__tags + & {
    @include mq($until: s) {
      margin-top: $spacing;
    }
  }

  @include mq(s) {
    width: col(7);
    margin-left: col(1);
  }
}

.infos__meta__share {
  margin-left: auto;

  @include mq($until: l) {
    margin-top: $spacing * 0.75;
    width: 100%;
  }
}

.infos__meta__item {
  @extend %fw-bold;

  margin-right: $spacing * 2;
  color: $c-green-dark;
  font-size: 1.8rem;
  line-height: 3rem;
}

.infos__meta__item__label {
  margin-bottom: 0.6rem;
  color: $c-green-light;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}
