
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


















































.footer {
  .social-network {
    justify-content: center;
  }

  @include mq($until: xxxl) {
    padding-right: col(1);
    padding-left: col(1);
  }
}

.footer-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3.6rem !important;

  @include mq($until: xl) {
    flex-direction: column;
    align-items: center;
  }
}

.footer__logo-outer {
  position: relative;
  flex-shrink: 0;
  width: 12.5rem;
  height: 7rem;
}

.footer__logo {
  @include image-fit(contain);
}

.footer__menu {
  width: 100%;

  @include mq(xl) {
    height: 100%;
    margin: 0 5rem 0 2.5rem;
    padding: 2rem;
    padding-right: 0;
    background-image: linear-gradient(
      to bottom,
      $c-gray-light 23%,
      rgba($c-black, 0) 0%
    );
    background-position: right;
    background-repeat: repeat-y;
    background-size: 2px 10px;
  }
}

.footer__menu__categories {
  display: flex;
  // justify-content: space-between;

  @include mq($until: xl) {
    flex-direction: column;
    align-items: center;
    margin-top: $spacing;
  }
}

.footer__menu__item {
  @include mq($until: xl) {
    @include bottom-dashed-line;

    width: 100%;
    padding: $spacing * 1.5;
    text-align: center;
  }

  @include mq(xl) {
    & + & {
      margin-left: $spacing * 2;
    }
  }

  @include mq(xxl) {
    & + & {
      margin-left: $spacing * 2.5;
    }
  }
}

.footer__menu__item__link {
  @extend %fw-bold;

  font-family: $ff-alt;
  text-decoration: none;
}

.footer__aside {
  @include mq($until: xl) {
    @include bottom-dashed-line;

    width: 100%;
    padding: 3.6rem;
    text-align: center;
  }
}
