
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.not-found__content {
  min-height: 50vh;
  margin-left: col(1);
  color: $c-green-dark;

  @include mq(xxxl) {
    margin: 0 auto;
  }
}

.not-found__text {
  margin-top: $spacing * 2.5;
  font-size: 1.8rem;

  @include mq(l) {
    width: col(6);
  }
}

.not-found__btn {
  display: inline-block;
  margin-top: $spacing * 2.5;
}
