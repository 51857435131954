
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































.duo {
  @include default-margin;

  position: relative;
  color: $c-green-dark;

  &.is-dark {
    padding-top: get-spacing(l, s) !important;
    padding-bottom: get-spacing(l, s) !important;
    color: $c-white;
    background-color: $c-green-dark;

    @include mq(m) {
      padding-top: get-spacing(l, m) !important;
      padding-bottom: get-spacing(l, m) !important;
    }

    @include mq(xl) {
      padding-top: get-spacing(l, l) !important;
      padding-bottom: get-spacing(l, l) !important;
    }
  }
}

.duo-inner {
  @include mq(m) {
    display: flex;
  }
}

.duo__header,
.duo__content {
  @include owl;

  width: col(14);

  @include mq($until: m) {
    margin: 0 col(1);
  }
}

.duo__header {
  @include mq(m) {
    width: col(6);
    margin-left: col(1);
  }
}

.duo__header__headline {
  @extend %ff-alt;

  color: $c-green-light;

  @include mq($until: m) {
    margin-bottom: 1rem;
  }
}

.duo__header__title {
  @extend %ff-alt;

  small {
    @extend %fw-normal;

    font-size: 1em;
  }
}

.duo__header__baseline {
  width: 80%;
  margin-top: $spacing;
  font-size: 1.8rem;
  opacity: 0.7;

  @include mq(m) {
    margin-top: $spacing * 1.5;
  }
}

.duo__header__link {
  margin-top: $spacing * 1.5;
}

.duo__content {
  @include mq($until: m) {
    margin-top: $spacing * 1.5;
  }

  @include mq(m) {
    width: col(7);
    margin-left: col(1);
  }

  h6 {
    color: $c-green-light;
    font-weight: 400;
  }

  ol {
    li {
      h5 {
        margin: 0;
      }

      p {
        margin-top: $spacing / 2;
        opacity: 0.8;
      }
    }
  }
}
