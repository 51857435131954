// Import fonts

/* stylelint-disable */
@font-face {
  font-family: century;
  src: url('./../assets/fonts/century-regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: century;
  src: url('./../assets/fonts/century-bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
}
@font-face {
  font-family: 'open-sans';
  src: url('./../assets/fonts/opensans-regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'open-sans';
  src: url('./../assets/fonts/opensans-bold.ttf') format('truetype');
  font-style: normal;
  font-weight: 900;
}
/* stylelint-enable */

a {
  color: $c-green-dark;

  &:hover {
    text-decoration: none;
  }
}

.htmltext a {
  @extend %ff-alt;

  font-size: 1.4rem;
  transition: color 0.1s ease-out;

  &:hover {
    color: $c-green-light;
  }

  @include mq(s) {
    font-size: 1.6rem;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  font-family: $ff-alt;
}

h1,
.h1,
[class*='h1--'] {
  @extend %fw-bold;

  font-size: 3.6rem;
  line-height: 1.2;

  [class*='--long'] {
    font-size: 2.6rem;
    line-height: 1.3;
  }

  @include mq(m) {
    font-size: 5.4rem;

    [class*='--long'] {
      font-size: 4.8rem;
    }
  }

  @include mq(xxl) {
    font-size: 7.4rem;

    &[class*='--long'] {
      font-size: 6rem;
    }
  }
}

h2,
.h2 {
  @extend %fw-normal;

  font-size: 3.8rem;
  line-height: 1.3;

  @include mq(l) {
    font-size: 5.2rem;
  }

  @include mq(xxl) {
    font-size: 6.4rem;
  }
}

h3,
.h3 {
  @extend %fw-normal;

  font-size: 2.4rem;
  line-height: 1.4;

  strong {
    @extend %fw-bold;
  }

  @include mq(l) {
    font-size: 2.8rem;
  }

  @include mq(xxl) {
    font-size: 3.6rem;
  }
}

h4,
.h4 {
  font-size: 2.2rem;
  line-height: 1.4;

  @include mq(l) {
    font-size: 2.6rem;
  }

  @include mq(xxl) {
    font-size: 3.2rem;
  }
}

h5,
.h5 {
  @extend %fw-bold;

  font-size: 2rem;
  line-height: 1.5;
}

h6,
.h6 {
  font-size: 1.8rem;
  line-height: 1.5;
}

body {
  @extend %fw-normal;

  font-family: $ff-default;
  font-size: 1.6rem;
  line-height: 2;
}

.quote {
  @extend %fw-normal;

  font-family: $ff-alt;
  font-size: 2.2rem;
  line-height: 1.4;

  strong {
    @extend %fw-bold;
  }

  @include mq(l) {
    font-size: 2.8rem;
  }

  @include mq(xxl) {
    font-size: 3.2rem;
  }
}

.lead {
  @extend %fw-normal;

  font-family: $ff-default;
  font-size: 1.8rem;
  line-height: 2;

  strong {
    @extend %fw-bold;
  }
}

.small {
  @extend %fw-normal;

  font-family: $ff-default;
  font-size: 1.4rem;
  line-height: 2;
}

.footnote {
  @extend %fw-normal;

  font-family: $ff-default;
  font-size: 1.3rem;
  font-style: italic;
  line-height: 2;

  strong {
    @extend %fw-bold;
  }
}
