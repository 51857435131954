
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































.link,
[class*='link--'] {
  position: relative;
  display: flex;
  width: fit-content;
  font-family: $ff-alt;
  font-weight: 900;
  text-decoration: none;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $c-green-dark;
    transform-origin: left;
    transform: scaleX(1);
    transition: transform 0.3s $ease-out-quad;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $c-white;
    transform-origin: left;
    transform: scaleX(0);
    transition: transform 0.3s $ease-out-quad;
  }

  &.white {
    &::after {
      background-color: $c-green-light;
    }

    .link__label::before {
      color: $c-green-light;
    }
  }

  &:hover,
  &:focus {
    background-size: 100% 1px;

    .link__label::before {
      max-width: 100%;
    }

    .link__icon {
      svg {
        fill: $c-white !important;
      }
    }

    &.white {
      .link__icon {
        svg {
          fill: $c-green-light !important;
        }
      }
    }

    &::after {
      transform: scaleX(1);
    }
  }

  .link__label {
    position: relative;

    &::before {
      content: attr(data-text);
      position: absolute;
      overflow: hidden;
      max-width: 0;
      color: $c-white;
      white-space: nowrap;
      transition: max-width 0.3s $ease-out-quad;
    }
  }

  &[class*='--background'] {
    padding: 0.6rem 2rem;
    color: $c-white;
    font-weight: 400;
    text-transform: uppercase;
    background-color: $c-green-dark;

    :hover > & {
      background-color: $c-green-light;
    }

    &::before {
      display: none;
    }
  }

  &.green {
    color: $c-green-light;

    &::before {
      background-color: $c-green-light;
    }
  }

  &.white {
    color: $c-white;

    &::before {
      background-color: $c-white;
    }
  }
}

.link__icon {
  display: inline-block;
  margin-left: 1rem;

  svg {
    fill: $c-green-dark;
    transition: 0.3s 0.1s $ease-out-quad;
  }

  [class*='link--'][class*='--background'] & {
    svg {
      fill: $c-white;
    }
  }

  .link.green & {
    svg {
      fill: $c-green-light;
    }
  }

  .link.white & {
    svg {
      fill: $c-white;
    }
  }
}
