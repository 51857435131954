
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































.packages {
  @include default-margin(0.5);

  position: relative;
  // overflow: hidden;
}

.packages__title {
  width: col(14);
  margin: 0 col(1);
  color: $c-gray-medium;
}

.packages__list {
  // position: relative;
  width: col(14);
  margin: 0 col(1);

  .packages__title + & {
    margin-top: $spacing * 3;
  }

  @include mq(l) {
    display: flex;
    justify-content: space-between;

    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      top: 135px;
      left: 0;
      width: 100vw;
      height: calc(100% - 229px);
      background-color: rgba($c-gray-lightest, 1);
      // transform: translateX(-150vw);
    }
  }
}

.packages__item {
  &:first-child {
    padding-top: 13.5rem;

    .package__head {
      display: none;
    }

    .package__body {
      padding-left: 0;
      background-color: $c-transparent;
      border: 0;
    }

    @include mq($until: l) {
      display: none;
    }

    @include mq(l) {
      flex-grow: 1;
      width: col(5);

      .package__body__row {
        justify-content: start;
      }

      .package__body__row__label {
        display: block;
      }

      .package__body__row__value {
        display: none;
      }
    }
  }

  @include mq(l) {
    flex-grow: 1;
    width: col(3.5);

    & + & {
      margin-left: $spacing;
    }
  }
}
