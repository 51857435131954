
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

























































.contact-address {
  position: relative;

  &::after {
    @include bottom-dashed-line(rgba($c-green-light, 0.2));

    content: '';
    position: absolute;
    bottom: 0;
    left: col(1);
    width: col(14);
    height: 2px;

    @include mq(m) {
      left: col(6);
      width: col(9);
    }
  }

  @include mq(m) {
    display: flex;
  }
}

.contact-address__title-outer {
  flex-shrink: 0;
  width: col(14);
  margin: 0 col(1);

  @include mq(m) {
    width: col(4);
    margin: 0 0 0 col(1);
  }
}

.contact-address__title {
  @extend %fw-bold;

  color: $c-white;
  font-size: 1.6rem;
  line-height: 1.2em;

  .is-white & {
    color: $c-green-dark;
  }

  @include mq(m) {
    max-width: 180px;
  }
}

.contact-address-inner {
  width: col(14);
  margin: 0 col(1);
  color: $c-white;
  font-size: 1.4rem;
  line-height: 1.2;

  @include mq(m) {
    width: col(9);
    margin: 0 0 0 col(1);
  }
}

.contact-address__headline {
  @extend %ff-alt;
  @extend %fw-bold;

  margin-top: 0;
  color: rgba($c-white, 0.4);
  font-size: 1.6rem;

  .is-white & {
    color: $c-green-dark;
  }

  @include mq($until: m) {
    margin-top: $spacing;
  }
}

.contact-address__office {
  @extend %ff-alt;
  @extend %fw-bold;

  color: $c-white;
  font-size: 1.8rem;

  .contact-address__headline + & {
    margin-top: $spacing;
  }
}

.contact-address__item {
  .contact-address__headline + &,
  .contact-address__office + & {
    margin-top: $spacing;
  }

  & + & {
    margin-top: $spacing;
  }
}
