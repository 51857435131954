
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













.embeds {
  @include default-margin;
}

.embeds-inner {
  width: col(14);
  margin: 0 col(1);
}
