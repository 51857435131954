
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











.popup-collapse {
  @include scrollbars(2px, $c-green-light, $c-gray-lighter);

  overflow-y: scroll;
  height: calc(100% - 8rem);
  margin-top: 4rem;
  margin-right: 4rem;
  margin-bottom: 4rem;

  @include mq(m) {
    height: calc(100% - 16rem);
    margin-top: 8rem;
    margin-bottom: 8rem;

    .duo:first-child {
      margin-top: 0;
    }
  }
}
