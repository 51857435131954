
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























.gallery-inner {
  position: relative;
}

.gallery__title {
  width: col(14);
  margin-left: col(1);

  @include mq(m) {
    width: col(7);
    margin-left: col(8);
  }

  margin-bottom: $spacing * 2.5;
  color: $c-green-dark;
}

.gallery__title--fullwidth {
  width: col(14);
  margin-left: col(1);

  @include mq(m) {
    width: 100%;
    margin-left: 0;
    text-align: center;
  }
}
