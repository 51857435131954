
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























.keyfact {
  @extend %text-center;

  color: $c-green-dark;
  font-size: 1.4rem;
  line-height: 1.8em;
}

.keyfact__title {
  @extend %ff-alt;
  @extend %fw-bold;

  color: $c-green-light;
}

.keyfact__htmltext {
  margin-top: $spacing / 4;
  font-size: 1.4rem;
  line-height: 1.3em;

  @include mq(m) {
    font-size: 1.6rem;
  }
}

.keyfact__icon {
  fill: $c-green-dark;
}
