
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















.app {
  position: relative;
  padding-top: 7rem;

  @include mq(l) {
    padding-top: 10rem;
  }
}
