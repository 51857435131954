
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































[class*='sc-link-inner--'] {
  @extend %text-nodecoration;
  @extend %fw-bold;

  &[class*='--link'],
  &[class*='--download'] {
    border-bottom: 2px solid $c-green-dark;
    transition: border 0.2s ease-out, color 0.2s ease-out;

    &:hover {
      border-bottom: 2px solid $c-green-light;
    }
  }

  &[class*='--button'] {
    padding: $spacing / 2;
    color: $c-white;
    background-color: $c-green-light;
    transition: background-color 0.2s ease-out, color 0.2s ease-out;

    &:hover {
      background-color: $c-green-dark;
    }
  }
}

.sc-link__icon {
  // display: inline-block;
  display: none;
  width: 16px;
  height: 16px;
  margin-left: $spacing / 2;
  background-color: $c-green-dark;

  [class*='sc-link-inner--']:hover & {
    background-color: $c-green-light;
  }
}
