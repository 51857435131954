
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























































.ticket {
  @extend %ff-alt;

  overflow: hidden;
  width: 100%;
  background-color: $c-white;
  border-radius: 5px;
}

.ticket__row {
  margin: 0 $spacing * 2;
  padding: $spacing * 1.5 0;

  &:first-child {
    padding-top: $spacing * 2;
  }

  & + & {
    @include top-dashed-line;
  }
}

.ticket__row__label {
  @extend %text-uppercase;

  color: $c-green-light;
  font-size: 1.2rem;
  line-height: 1em;
  white-space: nowrap;
}

.ticket__row__value {
  @extend %fw-bold;

  margin-top: $spacing / 2;
  color: $c-green-dark;
  font-size: 1.8rem;
  line-height: 1.2em;
}

.ticket__row__where {
  @extend %text-uppercase;

  margin-top: $spacing / 2;
  color: $c-gray-dark;
  font-size: 1.3rem;
  line-height: 1.2em;
}

.ticket__link {
  @extend %text-nodecoration;
  @extend %fw-bold;

  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  height: 69px;
  padding: 0 $spacing * 2;
  color: $c-white;
  font-size: 1.8rem;
  line-height: 1em;
  background-color: $c-yellow-medium;
  cursor: pointer;
  transition: background-color 0.2s ease-out;

  &:hover {
    background-color: darken($c-yellow-medium, 10%);
  }

  .event &,
  .training & {
    background-color: $c-red-medium;

    &:hover {
      background-color: darken($c-red-medium, 10%);
    }
  }
}

.ticket__link__arrow {
  position: relative;
  top: 1px;
  width: 12px;
  height: 10px;
  margin-left: $spacing / 2;
  fill: $c-white;
  transition: transform 0.2s ease-out;

  .ticket__link:hover & {
    transform: translateX(5px);
  }
}

.ticket-organizers {
  display: flex;
  flex-wrap: wrap;
}

.ticket-organizers__item {
  position: relative;
  width: 110px;
  margin-right: $spacing / 2;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: (68 / 110) * 100%;
  }
}

.ticket-organizers__item__picture {
  @include image-fit(contain, center left);

  user-select: none;
  pointer-events: none;
}
