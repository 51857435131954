
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





































































.cta-inner {
  .ctas.two & {
    @include mq(xl) {
      width: col(6, 8);
      margin-left: col(1, 8);
    }
  }
}
