
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        














.collapses {
  position: relative;
}

.collapses__item {
  width: 100%;
}
