
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































.general-contact {
  @include default-margin;

  background-color: $c-green-dark;

  &.is-white {
    background-color: $c-white;
  }

  .hero + &,
  .contact + & {
    margin-top: 0;
  }
}

.general-contact-inner {
  position: relative;

  .is-white &::after {
    @include bottom-dashed-line;

    content: '';
    position: absolute;
    bottom: 0;
    left: col(1);
    width: col(14);
    height: 2px;
  }

  @include mq(s) {
    display: flex;
  }
}

.general-contact__title {
  @extend %fw-bold;

  width: col(14);
  color: $c-white;
  font-size: 1.6rem;
  line-height: 1.2em;

  .general-contact.is-white & {
    color: $c-green-dark;
  }

  @include mq($until: s) {
    margin: 0 col(1);
  }

  @include mq(s) {
    width: col(6);
    margin: 0 0 0 col(1);
  }

  @include mq(m) {
    width: col(6);
  }

  @include mq(xl) {
    width: col(4);
  }
}

.general-contact__infos {
  width: col(14);

  @include mq($until: s) {
    margin: $spacing col(1) 0;
  }

  @include mq(s) {
    width: col(7);
    margin: 0 0 0 col(1);
  }

  @include mq(m) {
    display: flex;
    align-items: flex-start;
  }

  @include mq(xl) {
    width: col(9);
  }
}

.general-contact__infos__col {
  width: 100%;

  & + & {
    @include mq($until: m) {
      margin-top: $spacing * 1.5;
    }

    @include mq(m) {
      margin-left: col(1, 7);
    }
  }

  @include mq(m) {
    width: col(3, 7);
  }

  @include mq(xl) {
    width: col(4, 9);
  }
}

.general-contact__content__item {
  & + & {
    margin-top: $spacing;
  }
}

.contact__main,
.contact__related,
.general-contact__content {
  @include bottom-dashed-line;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-right: col(1);
  margin-left: col(1);

  & + & {
    margin-top: $spacing * 2;
  }

  @include mq($until: m) {
    flex-direction: column;
  }
}

.contact__main__items,
.contact__related__items {
  display: flex;
  flex-wrap: wrap;
  width: col(12);
  padding-right: col(2);
  padding-left: col(1);

  @include mq($until: m) {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: $spacing * 2;
    padding-right: 0;
  }
}

.contact__main__item,
.contact__related__item {
  width: col(4, 10);
  margin-top: col(1, 10);
  margin-right: col(1, 10);
  color: $c-white;
  font-size: 1.6rem;

  &:nth-child(1) {
    margin-top: col(0);
  }

  &:nth-child(2) {
    margin-top: col(0);
  }

  &:nth-child(2n) {
    margin-right: 0;
  }

  @include mq($until: m) {
    margin-right: 0;
  }
}

.contact__related__item {
  margin-bottom: $spacing;
}

.contact__main__item__title,
.contact__related__item__title {
  @extend %fw-bold;

  font-size: 1.6rem;
  opacity: 0.4;

  @include mq($until: m) {
    text-align: center;
  }
}

.contact__main__item-inner {
  display: flex;

  @include mq($until: m) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: $spacing;
    text-align: center;
  }
}

.contact__related__item-inner {
  display: flex;
  align-items: center;

  @include mq($until: m) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: $spacing;
    text-align: center;
  }
}

.contact__main__item__picture-outer {
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: 6.4rem;
  height: 6.4rem;
  margin-right: 2rem;
  background-color: $c-gray-lighter;
  border-radius: 6.4rem;

  @include mq($until: m) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

.contact__main__item__picture {
  @include image-fit;
}

.contact__main__item__name,
.contact__related__item__name {
  font-size: 1.4rem;
}

.contact__related__item__name {
  @extend %fw-bold;

  color: $c-white;
}

.contact__main__item__contact {
  a {
    color: $c-white;
    text-decoration: none;
  }
}

.contact__related__item__icon {
  fill: $c-green-light;
  margin-right: $spacing / 2;
}
