
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





















































.keyfacts {
  @include default-margin;
  @include bottom-dashed-line;

  margin: col(1);
  padding-bottom: $spacing * 2;

  @include mq(l) {
    padding-bottom: $spacing * 5;
  }
}

.keyfacts__title {
  @extend %text-center;

  color: $c-green-dark;
}

.keyfacts__list {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  &.col-even {
    margin: 0 col(1);
  }

  .keyfacts__title + & {
    margin-top: $spacing * 2;

    @include mq(l) {
      margin-top: $spacing * 5;
    }
  }
}

.keyfacts__item {
  width: 100%;
  padding: 0 $spacing * 2;

  @include mq($until: s) {
    & + & {
      margin-top: $spacing * 2;
    }
  }

  @include mq(s) {
    .col-even & {
      width: calc(100% / 2);

      &:nth-of-type(2n + 1) {
        border-right: 1px solid $c-green-light;
      }

      &:nth-of-type(n + 3) {
        margin-top: $spacing * 4;
      }
    }

    .col-odd & {
      width: 33.33%;

      &:nth-of-type(3n + 2),
      &:nth-of-type(3n + 3) {
        border-left: 1px solid $c-green-light;
      }

      &:nth-of-type(n + 4) {
        margin-top: $spacing * 4;
      }
    }
  }
}
